import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useLocation } from "react-router-dom"

const Networking = () => {
    
    const [outputVal, setOutputVal] = useState()
    const [isProcessing, setProcessing] = useState(false)
    const inputValue = useRef(null)
    
    const { hash } = useLocation()
    
    const determineOpFromUrl = useCallback(() => {

        const ip = {
            title: 'Your IP Address',
            prompt: ' ',
            resultTitle: 'IP Address',
            fn: async () => {
                const resp = await fetch('/api/ip')
                if (resp.ok) {
                    setOutputVal(await resp.text())
                } else {
                    setOutputVal(resp.statusText)
                }
            }
        }

        const siteCheck = {
            title: 'Down for everyone or just me?',
            prompt: 'URL',
            resultTitle: 'Is it down for everyone or just me?',
            placeHolder: 'ex. https://www.freewebutils.com',
            fn: async () => { 
                if (!inputValue.current.value) return
                const url = new URL(inputValue.current.value)
                const resp = await fetch('/api/sitecheck', {
                    method: "POST", 
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "same-origin",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    redirect: "follow",
                    referrerPolicy: "no-referrer",
                    body: JSON.stringify({hostname: url.hostname, protocol: url.protocol})
                })
                if (resp.ok) {
                    const r = await resp.json()
                    if (r.status) {
                        setOutputVal("It must be you, because it looks up to me...")
                    } else {
                        setOutputVal("It's not just you. It is down for me too...")
                    }
                } else {
                    setOutputVal(resp.statusText)
                }
            }
        }


        const dnsX = {
            title: 'DNS Check',
            prompt: 'Fully Qualified Domain Name',
            resultTitle: 'DNS Result',
            placeHolder: 'ex. www.freewebutils.com',
            fn: async () => { 
                const req = JSON.stringify({"fqdn": inputValue.current.value })
                if (!inputValue.current.value) return
                const resp = await fetch('/api/dnscheck', {
                    method: "POST", 
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "same-origin",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    redirect: "follow",
                    referrerPolicy: "no-referrer",
                    body: req
                })
                if (resp.ok) {
                    setOutputVal(JSON.stringify(JSON.parse(await resp.text()).payload,null,'  '))
                } else {
                    setOutputVal(resp.statusText)
                }
            }
        }

        return {
            '#ip': ip,
            '#siteCheck': siteCheck,
            '#dns': dnsX
        }[hash] || ip
    },[hash])

    const [operation, setOperation] = useState(determineOpFromUrl())

    const performOperation = async () => {
        setProcessing(true)
        setOutputVal('')
        await operation.fn(inputValue.current.value)
        setProcessing(false)
    }

    useEffect(() => {
        const op = determineOpFromUrl()
        if (!operation || operation.title !== op.title) {
            setOutputVal('')
            inputValue.current.value = ''
            setProcessing(false)
            setOperation(op)    
        }
        op.fn(inputValue.current.value)
    }, [operation,inputValue,determineOpFromUrl,setOutputVal,setProcessing,setOperation])

    return (
        <>
            <Row style={{ margin: '1rem' }}>
                <Col>
                    <Card style={{ minHeight: '18rem' }}>
                        <Card.Header>
                            <Row className='align-items-center'>
                                <Col className='me-auto text-start'>
                                    {operation.title}
                                </Col>
                                <Col className='ms-auto text-end'>
                                    <Dropdown as={ButtonGroup}>
                                        <Button>Network Operation</Button>
                                        <Dropdown.Toggle split id="dropdown-basic" />
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/networking#ip">Your IP Address</Dropdown.Item>
                                            <Dropdown.Item href="/networking#siteCheck">Site Check</Dropdown.Item>
                                            <Dropdown.Item href="/networking#dns">DNS Check</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col>
                                        <Row className={`mb-3 align-items-center ${(operation.title === 'Your IP Address') ? ' invisible' : ' visible'}`}>
                                            <Col>
                                                <Form.Group controlId="opInput">
                                                    <Form.Label>{operation.prompt}</Form.Label>
                                                    <Row>
                                                        <Col md={11}>
                                                            <Form.Control type="text" ref={inputValue} placeHolder={operation.placeHolder}/>
                                                        </Col>
                                                        <Col md={1}>
                                                            <Button onClick={performOperation} className={`text-end ${(isProcessing) ? 'disabled': 'enabled'}`}>Go</Button>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-2" controlId="opOutput" >
                                                    <Form.Label>{operation.resultTitle}</Form.Label>
                                                    <Form.Control as="textarea" rows={6} value={outputVal} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='ms-auto text-end'>
                                                <Button onClick={() => { navigator.clipboard.writeText(outputVal) }}>Copy</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Networking
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Hero from './Hero';

const fn = () => (
    <>
    <Hero />
    <Row style={{margin: '1rem'}}>
      <Col>
        <Card style={{minHeight: '18rem'}}>
          <Card.Header>Hash Utilities</Card.Header>
          <Card.Body>
            Supports calculating <a href="/hashing#md5">MD5</a>, <a href="/hashing#sha1">SHA1</a>, <a href="/hashing#sha256">SHA256</a>, 
                <a href="/hashing#sha384">SHA384</a>, <a href="/hashing#sha512">SHA512</a>, <a href="/hashing#sha512_224">SHA512/224</a>, 
                and <a href="/hashing#sha512_256">SHA512/256</a>. Calculate by pasting text into a box, or locating a file on disk. All hashing is done client-side.
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card style={{minHeight: '18rem'}}>
          <Card.Header>Encoding Utilities</Card.Header>
          <Card.Body>
            Supports <a href="/encoding#url">URL</a>, <a href="/encoding#html">HTML</a>, and <a href="/encoding#base64">Base64</a> en/de-coding. 
            Calculate by pasting text into a box! All encoding and decoding is done client-side.
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card style={{minHeight: '18rem'}}>
          <Card.Header>Network Utilities</Card.Header>
          <Card.Body>
            Access tools like <a href="networking#ip">"What is my IP Address?"</a>, 
            <a href="/networking#siteCheck">"Down for everyone (or just me)?"</a>, 
            and <a href="/networking#dns">DNS lookups</a>. We'll need the server's help on these utilities...
          </Card.Body>
        </Card>
      </Col>
    </Row>
    </>
)

export default fn
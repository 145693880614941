import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import ProgressBar from 'react-bootstrap/ProgressBar'
import InputGroup from 'react-bootstrap/InputGroup'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useLocation } from "react-router-dom"
import forge from "node-forge"

const Encoding = () => {
    
    const [encVal, setEncVal] = useState()
    const [readPercent, setReadPercent] = useState(0)
    const [hashMode, setHashMode] = useState()
    const [expectedVal, setExpectedVal] = useState()
    const [expectedMatch, setExpectedMatch] = useState(null)
    const originalValue = useRef(null)
    const fileValue = useRef(null)
    const hashedValue = useRef(null)
    const expectedValue = useRef(null)
    const { hash } = useLocation()
    
    const determineHashFromUrl = useCallback(() => {
        const md5 = {
            title: 'MD5 Hash',
            fn: forge.md.md5
        }
        
        const sha1 = {
            title: 'SHA1 Hash',
            fn: forge.md.sha1
        }
        
        const sha256 = {
            title: 'SHA256 Hash',
            fn: forge.md.sha256
        }
        
        const sha384 = {
            title: 'SHA256 Hash',
            fn: forge.md.sha384
        }
        
        
        const sha512 = {
            title: 'SHA512 Hash',
            fn: forge.md.sha512
        }

        const sha512_224 = {
            title: 'SHA512/224 Hash',
            fn: forge.md.sha512.sha224
        }

        const sha512_256 = {
            title: 'SHA512/256 Hash',
            fn: forge.md.sha512.sha256
        }

        return {
            "#md5" : md5,
            "#sha1" : sha1,
            "#sha256": sha256,
            "#sha384": sha384,
            "#sha512": sha512,
            "#sha512_224": sha512_224,
            "#sha512_256": sha512_256
        }[hash] || md5
    },[hash])

    const [hasher, setHasher] = useState(determineHashFromUrl());

    const textHash = useCallback((md, value) => {
        md.update(value)
        setEncVal(md.digest().toHex())
    },[setEncVal])

    const performTextHash = useCallback((evt) => {
        if (!evt || !evt.target || (evt.target && evt.target.value === '')) {
            setEncVal('')
            return
        }
        setHashMode('text')
        textHash(hasher.fn.create(), evt.target.value)
    },[textHash, hasher, setHashMode, setEncVal])

    const fileHash = useCallback((md, file) => {
        setEncVal('')
        originalValue.current.value = ''
		const size = file.size
		const blocksize = 1048576
        let total = 0

		setReadPercent(0);

		const rdr = (start) => {
			const blob = file.slice(start, start + blocksize)
			const reader = new FileReader()
			reader.onload = (evt) => {
				md.update(evt.target.result)
				total += evt.target.result.length
				setReadPercent(Math.floor((total / size) * 100))
				if (total >= size) {
					setEncVal(md.digest().toHex())
                    setReadPercent(0)
                    fileValue.current.value = null
				} else {
                    rdr(start + blocksize)
				}
			}
			reader.readAsBinaryString(blob)
		}
		rdr(0)
    },[])

    const performFileHash = useCallback((evt) => {
        if (!evt || !evt.target || !evt.target.files[0]) {
            setEncVal('')
            return
        }
        setHashMode('file')
        fileHash(hasher.fn.create(), evt.target.files[0])
    },[fileHash, hasher, setHashMode])

    const handleExpectedValChange = (e) => {
        e.preventDefault()
        setExpectedVal(e.target.value)
    }

    useEffect(() => {

        const good = (<svg xmlns="http://www.w3.org/2000/svg" height="20px" version="1.1" viewBox="0 0 20 20" width="20px"><g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><g fill="green" transform="translate(-44.000000, -86.000000)"><g transform="translate(44.000000, 86.000000)"><path d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M8,15 L3,10 L4.4,8.6 L8,12.2 L15.6,4.6 L17,6 L8,15 L8,15 Z" /></g></g></g></svg>)
    
        const bad = (<svg xmlns="http://www.w3.org/2000/svg"  height="20px" version="1.1" viewBox="0 0 20 20" width="20px"><g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><g fill="red" transform="translate(-380.000000, -44.000000)"><g transform="translate(380.000000, 44.000000)"><path d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M15,13.6 L13.6,15 L10,11.4 L6.4,15 L5,13.6 L8.6,10 L5,6.4 L6.4,5 L10,8.6 L13.6,5 L15,6.4 L11.4,10 L15,13.6 L15,13.6 Z" /></g></g></g></svg>)
        
        const empty = (<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor" viewBox="0 0 20 20"></svg>)

        const h = determineHashFromUrl()
        if (h.title !== hasher.title) {
            setHasher(h)
            if (hashMode === 'text') {
                textHash(h.fn.create(),originalValue.current.value)
            } else {
                setHashMode('file')
                setEncVal('')
            }
        }
        if (!encVal || !expectedVal) {
            setExpectedMatch(empty)
        } else if (encVal === expectedVal) {
            setExpectedMatch(good)
        } else {
            setExpectedMatch(bad)
        }
    },[hash, hasher, setHasher, determineHashFromUrl, fileHash, hashMode, setHashMode, setEncVal, textHash, expectedVal, encVal, setExpectedMatch])


    return (
    <>
    <Row style={{margin: '1rem'}}>
        <Col>
        <Card style={{minHeight: '18rem'}}>
            <Card.Header>
                <Row className='align-items-center'>
                    <Col className='me-auto text-start'>
                        {hasher.title}
                    </Col>
                    <Col className='ms-auto text-end'>
                        <Dropdown as={ButtonGroup}>
                            <Button>Hashing Algorithm</Button>
                            <Dropdown.Toggle split id="dropdown-basic" />
                            <Dropdown.Menu>
                                <Dropdown.Item href="/hashing#md5">MD5 Hash</Dropdown.Item>
                                <Dropdown.Item href="/hashing#sha1">SHA1 Hash</Dropdown.Item>
                                <Dropdown.Item href="/hashing#sha256">SHA256 Hash</Dropdown.Item>
                                <Dropdown.Item href="/hashing#sha384">SHA384 Hash</Dropdown.Item>
                                <Dropdown.Item href="/hashing#sha512">SHA512 Hash</Dropdown.Item>
                                <Dropdown.Item href="/hashing#sha512_224">SHA512/224 Hash</Dropdown.Item>
                                <Dropdown.Item href="/hashing#sha512_256">SHA512/256 Hash</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={e => { e.preventDefault() }}>
                    <Row>
                        <ProgressBar animated now={readPercent} className={`${(readPercent % 100 === 0) ? ' invisible' : ' visible'}`} label={`${readPercent}%`} />
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="hashFileInput">
                                <Form.Label>Local File:</Form.Label>
                                <Form.Control type="file" onChange={performFileHash} ref={fileValue} />
                            </Form.Group>
                            <hr></hr>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="expectedInput">
                                <Form.Label>Expected Hash:</Form.Label>
                                <InputGroup>
                                    <Form.Control type="text" placeholder="e.g. 6f5902ac237024bdd0c176cb93063dc4" onChange={handleExpectedValChange} value={expectedVal} ref={expectedValue} />
                                    <InputGroup.Text className="align-items-center w-6">{expectedMatch}</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="hashTextInput">
                                <Form.Label>Enter text to be encoded:</Form.Label>
                                <Form.Control as="textarea" rows={6} onChange={performTextHash} ref={originalValue} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="encodeOutput" >
                                <Form.Label>Hashed result:</Form.Label>
                                <Form.Control as="textarea" rows={6} value={encVal} ref={hashedValue} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='ms-auto text-end'>
                            <Button onClick={() => {navigator.clipboard.writeText(encVal)}}>Copy</Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
        </Col>
    </Row>
    </>
    )
}

export default Encoding
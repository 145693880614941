// client/src/App.js

import React from "react"
import "./App.css"
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Main from './Components/Main'
import Hashing from './Components/Hashing'
import Encoding from './Components/Encoding'
import Networking from './Components/Networking'
import About from './Components/About'
import { createBrowserRouter, RouterProvider } from "react-router-dom"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/home",
    element: <Main />,
  },
  {
    path: "/hashing",
    element: <Hashing />
  },
  {
    path: "/encoding",
    element: <Encoding />
  },
  {
    path: "/networking",
    element: <Networking />
  },
  {
    path: "/about",
    element: <About />
  }

])

function App() {
  return (
    <>
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/home"><div className="align-middle"><img src="lambda.png" className="align-middle" style={{height: '25px', marginTop: '-5px', paddingRight: '5px'}} alt="λ" />FreeWebUtils</div></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/hashing">Hashing</Nav.Link>
            <Nav.Link href="/encoding">Encoding</Nav.Link>
            <Nav.Link href="/networking">Networking</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <RouterProvider router={router} />
    </>
    );
}

export default App
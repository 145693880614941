import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import { useState, useRef, useEffect, useMemo } from 'react'
import { useLocation } from "react-router-dom"

const Encoding = () => {

    const [decVal, setDecVal] = useState()
    const [encVal, setEncVal] = useState()
    const [encMode, setEncMode] = useState('encoder')
    const encodeOriginalValue = useRef(null)
    const decodeOriginalValue = useRef(null)


    const html = useMemo(() => { 
        return {
            title: 'HTML Encode',
            encode: (val) => val.replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/'/g, '&#39;')
                .replace(/"/g, '&#34;'),
            decode: (val) => val.replace(/&#34;/g, '"')
                .replace(/&#39;/g, '\'')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<')
                .replace(/&amp;/g, '&')
        }
    },[])
    
    const url = useMemo(() => {
        return {
            title: 'URL Encode',
            encode: (val) => encodeURIComponent(val.trim()),
            decode: (val) => {
                try {
                    return decodeURIComponent(val.trim())
                } catch (e) {
                    return ''
                }
            }
        }
    },[])

    const base64 = useMemo(() => {
        return {
            title: 'Base64 Encode',
            encode: (val) => {
                try {
                    return btoa(val)
                } catch(e) {
                    return ''
                }
            },
            decode: (val) => {
                try {
                    return atob(val)
                } catch (e) {
                    return ''
                }
            }
        }
    },[])

    const { hash } = useLocation()

    const [algorithm, setAlgorithm] = useState(url);

    const decodeVal = (c) => {
        if (encMode === 'decode') {
            let v = algorithm.decode(c.target.value)
            setEncVal(c.target.value)
            setDecVal(v)
        }
    }

    const encodeVal = (c) => {
        if (encMode === 'encode') {
            let v = algorithm.encode(c.target.value)
            setDecVal(c.target.value)
            setEncVal(v)
        }
    }

    const setEncoder = () => {
        setEncMode('encode')
    }

    const setDecoder = () => {
        setEncMode('decode')
    }

    useEffect(() => {
        const determineAlgFromUrl = () => {
            return {
                "#url" : url,
                "#html": html,
                "#base64": base64
            }[hash] || url;
        }    
        const changeAlgorithm = (alg) => {
            setAlgorithm(alg)
            setEncMode('encode')
            setEncVal(algorithm.encode(decodeOriginalValue.current.value))
        }    
        changeAlgorithm(determineAlgFromUrl())
    },[base64, hash, html, url, setEncMode, setEncVal, algorithm, decodeOriginalValue])

    return (
    <>
    <Row style={{margin: '1rem'}}>
        <Col>
            <Card style={{minHeight: '18rem'}}>
                <Card.Header>
                    <Row className='align-items-center'>
                        <Col className='me-auto text-start'>
                            {algorithm.title}
                        </Col>
                        <Col className='ms-auto text-end'>
                            <Dropdown as={ButtonGroup}>
                                <Button>Encoder/Decoder</Button>
                                <Dropdown.Toggle split id="dropdown-basic" />
                                <Dropdown.Menu>
                                    <Dropdown.Item href="/encoding#url">URL Encode</Dropdown.Item>
                                    <Dropdown.Item href="/encoding#html">HTML Encode</Dropdown.Item>
                                    <Dropdown.Item href="/encoding#base64">Base64 Encode</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="encodeInput">
                                            <Form.Label>Enter text to be encoded:</Form.Label>
                                            <Form.Control as="textarea" rows={6} onChange={encodeVal} onFocus={setEncoder} value={decVal} ref={decodeOriginalValue} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='ms-auto text-start'>
                                        <Button onClick={() => {navigator.clipboard.writeText(decodeOriginalValue.current.value)}}>Copy</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="encodeOutput" >
                                            <Form.Label>Encoded result:</Form.Label>
                                            <Form.Control as="textarea" rows={6} onChange={decodeVal} onFocus={setDecoder} value={encVal} ref={encodeOriginalValue} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='ms-auto text-end'>
                                        <Button onClick={() => {navigator.clipboard.writeText(encodeOriginalValue.current.value)}}>Copy</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    </Row>
    </>
    )
}

export default Encoding
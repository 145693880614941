import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useState, useEffect } from 'react'

const About = () => {

    const [credits, setCredits] = useState({})

    useEffect(() => {
        async function fetchData() {
            const resp = await fetch('/api/credits')
            if (resp.ok) {
                setCredits(await resp.json())
            } else {
                setCredits('')
            }
        }
        fetchData()
    },[credits, setCredits])

    return (
    <>
    <Row style={{ margin: '1rem' }}>
        <Col>
            Many thanks to these fine software developers for their outstanding products. Without them this would have been way more work and a ton less fun! --CG
        </Col>
    </Row>        
    {Object.keys(credits).map((key,i) => {
        return (
        <Row style={{margin: '1rem'}} id={`credit_${i}`}>
            <Col>
                <Card>
                    <Card.Header>
                        {key} {credits[key].version}
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {credits[key].author} {credits[key].homepage} <a href="{credits[key].homepage}">{credits[key].homepage}</a>
                        </Card.Text>
                    </Card.Body>
                </Card>            
            </Col>
        </Row>)
        })}
    </>
    )
}

export default About
 const fn = () => (
    <div class="mt-4 p-5" style={{ backgroundColor: '#A459A3', textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', color: '#F5F5F5' }}>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div><img src="lambda.png" alt="λ" /></div><div style={{ width: '50px' }}></div><div><h1>Free Web Utils</h1></div>
        </div>
        <div style={{ display: 'flex', height: '25px' }}></div>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div>Your swiss army cloud toolchain. A little rough around the edges, but what useful thing isn't...</div>
        </div>
    </div>)
export default fn    